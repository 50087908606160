<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    style="z-index:200000001;"
  >
    <v-card>
      <v-card-title class="d-flex justify-between app-bold-font">
        Add {{ type }} Icon
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text style="font-family:'Poppins-Regular';">
        Add your {{ type.toLowerCase() }} icons that will be used on the site.
        We highly recommend recommend 512x512 svg file for the icon.
      </v-card-text>
      <v-card-text
        class="d-flex justify-center flex-column app-medium-font dark-font-color "
      >
        Select Icon
        <div class="d-flex justify-center">
          <editable-rect-avatar
            :image="profile.image"
            :onImageSelected="onImageSelected"
          />
        </div>
      </v-card-text>
      <v-card-text class="d-flex justify-center flex-column">
        <div class="app-medium-font dark-font-color">
          Name
        </div>

        <v-text-field
          label="Name"
          solo
          flat
          outlined
          class="mt-2 app-regular-font"
          v-model="profile.name"
          :rules="[rules.required]"
        />
        <div class="app-medium-font dark-font-color">
          Color
        </div>
        <v-text-field
          label="Color code"
          solo
          flat
          outlined
          class="mt-2 app-regular-font"
          v-model="profile.color"
          :rules="[rules.required]"
        />
      </v-card-text>
      <v-card-text v-if="message">
        <v-alert dense type="error" v-text="message" v-if="message" />
      </v-card-text>
      <v-card-actions class="pb-6 mt-3 d-flex justify-center">
        <v-btn
          color="#b5e539"
          @click="onSave"
          class="white--text"
          width="150"
          :loading="loading"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import EditableRectAvatar from "@/components/EditableRectAvatar.vue";
export default {
  components: { EditableRectAvatar },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    type: String,
    onClose: {
      type: Function
    }
  },
  data() {
    return {
      code: "",
      name: "",
      message: null,
      loading: false,
      sendingCode: false,
      disableResend: false,
      resendCount: 0,
      rules: {
        required: value => !!value || "Required."
      },
      selectedImage: null,
      profile: {}
    };
  },
  methods: {
    ...mapActions("icon", {
      addIcon: "addIcon"
    }),
    onChange() {
      this.message = null;
    },
    onSave() {
      if (!this.profile.image && !this.selectedImage) {
        this.message = "Please select your icon image.";
        return;
      }
      if (!this.profile.name) {
        this.message = "Please add the icon name.";
        return;
      }
      if (!this.profile.color) {
        this.message = "Please add the color code.";
        return;
      }
      this.message = null;
      var formData = new FormData();
      if (this.selectedImage) {
        formData.append("image", this.selectedImage);
      }
      formData.append("name", this.profile.name);
      formData.append("color", this.profile.color);
      formData.append("type", this.type);
      this.loading = true;
      this.addIcon(formData)
        .then(() => {
          this.loading = false;
          this.onClose();
        })
        .catch(error => {
          this.loading = false;
          if (error.response) {
            this.message = error.response.data.message;
          } else {
            this.message = error;
          }
        });
    },
    onImageSelected(image) {
      this.selectedImage = image;
      this.message = null;
    }
  }
};
</script>
<style scoped></style>
