<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    style="z-index:200000001;"
  >
    <v-card>
      <v-card-title class="d-flex justify-between app-bold-font">
        Edit {{ type }} Icon
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text style="font-family:'Poppins-Regular';">
        Update your {{ type.toLowerCase() }} icons that will be used on the
        site. We highly recommend recommend 512x512 svg file for the icon.
      </v-card-text>
      <v-card-text
        class="d-flex justify-center flex-column app-medium-font dark-font-color "
      >
        Select Icon
        <div class="d-flex justify-center">
          <editable-rect-avatar
            :image="profile.image"
            :onImageSelected="onImageSelected"
          />
        </div>
      </v-card-text>
      <v-card-text class="d-flex justify-center flex-column">
        <div class="app-medium-font dark-font-color">
          Name
        </div>

        <v-text-field
          label="Name"
          solo
          flat
          outlined
          class="mt-2 app-regular-font"
          v-model="profile.name"
          :rules="[rules.required]"
        />
        <div class="app-medium-font dark-font-color">
          Color
        </div>
        <v-text-field
          label="Color code"
          solo
          flat
          outlined
          class="mt-2 app-regular-font"
          v-model="profile.color"
          :rules="[rules.required]"
        />
      </v-card-text>
      <v-card-text v-if="message">
        <v-alert dense type="error" v-text="message" v-if="message" />
      </v-card-text>
      <v-card-actions class="pb-6 mt-3 d-flex justify-center">
        <v-btn
          color="#b5e539"
          @click="onSave"
          class="white--text"
          width="150"
          :loading="loading"
          >Save</v-btn
        >
        <v-btn
          color="#ff5252"
          @click="onDelete"
          class="white--text"
          width="150"
          :loading="isDeleting"
          >Delete</v-btn
        >
      </v-card-actions>
      <confirm-delete-dialog
        :deleteDialog="deleteDialog"
        :onClose="onCloseDeleteDialog"
        :onConfirm="onConfirmDelete"
        :loading="isDeleting"
      />
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import EditableRectAvatar from "@/components/EditableRectAvatar.vue";
import ConfirmDeleteDialog from "@/components/common/ConfirmDeleteDialog.vue";
export default {
  components: { EditableRectAvatar, ConfirmDeleteDialog },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    type: String,
    icon: Object,
    onClose: {
      type: Function
    }
  },
  data() {
    return {
      code: "",
      name: "",
      message: null,
      loading: false,
      isDeleting: false,
      sendingCode: false,
      disableResend: false,
      resendCount: 0,
      rules: {
        required: value => !!value || "Required."
      },
      selectedImage: null,
      profile: {},
      deleteDialog: false
    };
  },
  methods: {
    ...mapActions("icon", {
      updateIcon: "updateIcon",
      deleteIcon: "deleteIcon"
    }),
    onChange() {
      this.message = null;
    },
    onSave() {
      if (!this.profile.image && !this.selectedImage) {
        this.message = "Please select your icon image.";
        return;
      }
      if (!this.profile.name) {
        this.message = "Please add the icon name.";
        return;
      }
      if (!this.profile.color) {
        this.message = "Please add the color code.";
        return;
      }
      this.message = null;
      var formData = new FormData();
      if (this.selectedImage) {
        formData.append("image", this.selectedImage);
      }
      formData.append("name", this.profile.name);
      formData.append("color", this.profile.color);
      formData.append("_id", this.profile._id);
      console.log(this.profile);
      this.loading = true;
      this.updateIcon(formData)
        .then(() => {
          this.loading = false;
          this.onClose();
        })
        .catch(error => {
          this.loading = false;
          if (error.response) {
            this.message = error.response.data.message;
          } else {
            this.message = error;
          }
        });
    },
    onImageSelected(image) {
      this.selectedImage = image;
      this.message = null;
    },
    onDelete() {
      this.deleteDialog = true;
    },
    onCloseDeleteDialog() {
      this.deleteDialog = false;
    },
    onConfirmDelete() {
      this.isDeleting = true;
      this.deleteIcon({ _id: this.profile._id })
        .then(() => {
          this.isDeleting = false;
          this.deleteDialog = false;
          this.onClose();
        })
        .catch(error => {
          this.isDeleting = false;
          console.log(error);
        });
    }
  },
  mounted() {
    this.profile = { ...this.icon };
  }
};
</script>
<style scoped></style>
