<style>
.left-popup-setting .v-image__image--cover {
  filter: brightness(0.2) !important;
}
.left-popup-setting .app-medium-font.white--text {
  color: #000 !important;
  caret-color: #000 !important;
}
.left-popup-setting selected .list-item .v-image__image--cover {
  filter: brightness(0.2) !important;
}
.left-popup-setting .list-item:hover {
  background: #55eef3f8;
}
</style>
<template>
  <div
    class="w-100 mx-0 d-flex flex-column align-center"
    style="min-width: 200px;"
  >
    <div class="hidden-md-and-up w-100 px-3">
      <v-menu offset-y rounded="lg" left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list class="pa-0 left-popup-setting" style="width: 260px;">
          <setting-list-card
            :title="category.title"
            :image="category.image"
            :image_selected="category.image_selected"
            v-for="category in categories"
            :key="category.title"
          />
        </v-list>
      </v-menu>
    </div>
    <div class="w-100 align-center d-flex flex-column pa-5 hover-list">
      <settings-account-section v-if="category == 'Account'" />
      <settings-notification-section v-else-if="category == 'Notifications'" />
      <settings-password-section v-else-if="category == 'Password'" />
      <settings-region-section v-else-if="category == 'Language and region'" />
      <settings-blocked-section v-else-if="category == 'Blocked'" />
      <settings-site-settings-section
        v-else-if="category == 'Site settings' && profileType == 'Admin'"
      />
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import SettingsNotificationSection from "./SettingsNotificationSection.vue";
import SettingsPasswordSection from "./SettingsPasswordSection.vue";
import SettingsAccountSection from "./SettingsAccountSection.vue";
import SettingsRegionSection from "./SettingsRegionSection.vue";
import SettingsBlockedSection from "./SettingsBlockedSection.vue";
import SettingsSiteSettingsSection from "./SettingsSiteSettingsSection.vue";
import { mapState } from "vuex";
import SettingListCard from "./SettingListCard.vue";

export default {
  components: {
    SettingsNotificationSection,
    SettingsPasswordSection,
    SettingsAccountSection,
    SettingsRegionSection,
    SettingsBlockedSection,
    SettingsSiteSettingsSection,
    SettingListCard
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      category: "",
      categories: [
        {
          title: "Account",
          image: require("@/assets/svg/settings/icon_account_setting.svg"),
          image_selected: require("@/assets/svg/settings/icon_account_setting.svg")
        },
        {
          title: "Notifications",
          image: require("@/assets/svg/settings/icon_notification.svg"),
          image_selected: require("@/assets/svg/settings/icon_notification.svg")
        },
        {
          title: "Password",
          image: require("@/assets/svg/settings/icon_password_setting.svg"),
          image_selected: require("@/assets/svg/settings/icon_password_setting.svg")
        },
        {
          title: "Language and region",
          image: require("@/assets/svg/settings/icon_language_setting.svg"),
          image_selected: require("@/assets/svg/settings/icon_language_setting.svg")
        },
        {
          title: "Blocked",
          image: require("@/assets/svg/settings/icon_blocked.svg"),
          image_selected: require("@/assets/svg/settings/icon_blocked.svg")
        }
      ]
    };
  },
  computed: {
    ...mapState("auth", {
      profileType: "type"
    })
  },
  watch: {
    "$route.query.category": function(newValue) {
      this.category = newValue;
      console.log("this.category===", newValue);
    }
  },
  mounted() {
    this.category = this.$route.query.category;
  }
};
</script>
