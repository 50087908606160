<template>
  <keep-alive>
    <v-container fluid class="d-flex flex-column h-100 pa-0 min-height-inherit">
      <div class="d-flex flex-row h-100 pa-0 min-height-inherit max-width-1500">
        <setting-left-section class="hidden-sm-and-down" />
        <setting-feed-section />
      </div>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-container>
  </keep-alive>
</template>
<script>
import { mapGetters } from "vuex";
import SettingFeedSection from "./SettingFeedSection.vue";
import SettingLeftSection from "./SettingLeftSection.vue";
export default {
  components: {
    SettingFeedSection,
    SettingLeftSection
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      notifications: [],
      errorNotifications: []
    };
  },
  methods: {
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    }
  },
  computed: {
    ...mapGetters({
      profileType: "auth/getType"
    })
  }
};
</script>
<style scoped></style>
