<style></style>

<template>
  <div class="left-section left-popup">
    <div class="d-flex flex-column pt-5" style="width: 320px;">
      <span class="text-msg white--text  font-30 app-medium-font mb-3">{{
        $t("settings")
      }}</span>
      <setting-list-card
        :title="category.title"
        :image="category.image"
        :image_selected="category.image_selected"
        v-for="category in categories"
        :key="category.title"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import SettingListCard from "./SettingListCard.vue";
export default {
  components: { SettingListCard },
  data() {
    return {
      categories: [
        {
          title: "Account",
          image: require("@/assets/svg/settings/icon_account_setting.svg"),
          image_selected: require("@/assets/svg/settings/icon_account_setting_selected.svg")
        },
        {
          title: "Notifications",
          image: require("@/assets/svg/settings/icon_notification.svg"),
          image_selected: require("@/assets/svg/settings/icon_notification_selected.svg")
        },
        {
          title: "Password",
          image: require("@/assets/svg/settings/icon_password_setting.svg"),
          image_selected: require("@/assets/svg/settings/icon_password_setting_selected.svg")
        },
        {
          title: "Language and region",
          image: require("@/assets/svg/settings/icon_language_setting.svg"),
          image_selected: require("@/assets/svg/settings/icon_language_setting_selected.svg")
        },
        {
          title: "Blocked",
          image: require("@/assets/svg/settings/icon_blocked.svg"),
          image_selected: require("@/assets/svg/settings/icon_blocked_selected.svg")
        }
      ]
    };
  },
  computed: {
    ...mapState("auth", {
      profileType: "type"
    })
  },
  methods: {},
  mounted() {
    if (!this.$route.query.category) {
      this.$router.replace({
        query: { category: "Account" }
      });
    }
    if (this.profileType == "Admin") {
      this.categories.push({
        title: "Site settings",
        image: require("@/assets/svg/settings/icon_site_settings.svg"),
        image_selected: require("@/assets/svg/settings/icon_site_settings_selected.svg")
      });
    }
  }
};
</script>
<style scoped>
.left-section {
  max-height: 0;
  overflow-y: hidden;
  position: sticky;
  min-height: inherit;
  overflow-x: hidden;
  top: 56px;
  max-width: 430px;
  min-width: 430px;
  width: 430px;
  padding-left: 100px;
}
.left-section:hover {
  overflow-y: auto;
}
.left-section::-webkit-scrollbar {
  width: 8px;
  transition-duration: 0.5s;
}

/* Track */
.left-section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.left-section::-webkit-scrollbar-thumb {
  background: rgba(240, 160, 0, 0.3);
  border-radius: 3px;
}

/* Handle on hover */
.left-section::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
